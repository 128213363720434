/* Your Global Styles */

:root {
  --primary: #34408c;
  --grey: grey;
  --white: #ffffff;
  --background: #E6E6E6;
}

body {
  background: var(--background) !important;
}

.healthvine__btn-login { 
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  margin-top: -20px;
}

.healthvine__link, .healthvine__link:hover { 
  color: var(--grey);
}

.healthvine__input {
  background-color: var(--white) !important;
}

.healthvine__header { 
  background-color: var(--primary);
  height: 20px;
}

.healthvine__header-logo {
  height: 100px;
  padding: 20px 35px;
}

.card-title {
  font-size: 2em !important;
  color: var(--grey) !important;
}

.flag-icon {
  display: none;
}

.card { 
  background-color: #F2F2F2;
}

.healthvine__btn-primary {
  background-color: var(--primary) !important;
  border: none;
}

.healthvine__primary {
  color: var(--primary) !important;;
}

.btn.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary);
}

.btn-primary.dropdown-toggle {
  border-color: var(--primary) !important;
}

//SHEL-155 Remove organization units tab
#user-organization-units, #user-roles{
  display: none;
}
